import { shadowTypes } from "../styles/UnitShadow";
import BaseURL from "./images/Hosting-BaseURL";
import { emptyImage } from "../styles/UnitImage";

const imagePath = `${BaseURL}/Mechs/Horus/Balor`;

// BODY
const bodyBalorCombatImage = `${imagePath}/Chassis.png`;
const bodyBalorAltImage = `${imagePath}/balor alt.png`;

// HEAD
const headBalorImage = `${imagePath}/heads/Head - Balor.png`;
const headKazuImage = `${imagePath}/heads/Head - Kazu.png`;
const headCyclopsImage = `${imagePath}/heads/Head - Cyclops.png`;
const headInfernaImage = `${imagePath}/heads/Head - Inferna.png`;
const headMaliceImage = `${imagePath}/heads/Head - Malice.png`;
const headOniImage = `${imagePath}/heads/Head - Oni.png`;
const headScopehoundImage = `${imagePath}/heads/Head - Scopehound.png`;

// LEFT WEAPON
const weaponLeftHandImage = `${imagePath}/weapons/Weapon - Left - Hand.png`;
const weaponLeftClipWhipImage = `${imagePath}/weapons/Weapon - Left - Clip Whip.png`;
const weaponLeftNaniteWhipImage = `${imagePath}/weapons/Weapon - Left - Nanite Whip.png`;
const weaponLeftNaniteClawImage = `${imagePath}/weapons/Weapon - Left - Nanite Claw.png`;
const weaponLeftAxeImage = `${imagePath}/weapons/Weapon - Left - Axe.png`;
const weaponLeftShotgunImage = `${imagePath}/weapons/Weapon - Left - Shotgun.png`;
const weaponLeftFerrofluidSpearImage = `${imagePath}/weapons/Weapon - Left - Ferrofluid Spear.png`;
const weaponLeftPilebunkerImage = `${imagePath}/weapons/Weapon - Left - Pilebunker.png`;
const weaponLeftSwordImage = `${imagePath}/weapons/balor sword 2.png`;
const weaponLeftLeviathanCannonUrsineImage = `${imagePath}/weapons/balor leviathan cannon - ursine.png`;

// RIGHT WEAPON
const weaponRightHandCannonImage = `${imagePath}/weapons/Weapon - Right - Hand and Cannon.png`;
const weaponRightNaniteClawImage = `${imagePath}/weapons/Weapon - Right - Nanite Claw.png`;
const weaponRightClawWhipImage = `${imagePath}/weapons/Weapon - Right - Claw Whip.png`;
const weaponRightDecksweeperImage = `${imagePath}/weapons/Weapon - Right - Decksweeper.png`;
const weaponRightFlamethrowerImage = `${imagePath}/weapons/Weapon - Right - Flamethrower.png`;
const weaponRightAxeImage = `${imagePath}/weapons/Weapon - Right - Axe.png`;
const weaponRightWarPikeImage = `${imagePath}/weapons/Weapon - Right - War Pike.png`;
const weaponRightPilebunkerImage = `${imagePath}/weapons/Weapon - Right - Pilebunker.png`;
const weaponRightSwordImage = `${imagePath}/weapons/balor sword 1.png`;
const weaponRightAnnihilatorImage = `${imagePath}/weapons/balor annihilator.png`;

// FLAMES
// HEAD
const glowFlamesBalorImage = `${imagePath}/glow/Head Glow - Balor.png`;
const glowFlamesKazuImage = `${imagePath}/glow/Head Glow - Kazu.png`;
const glowFlamesInfernaImage = `${imagePath}/glow/Head Glow - Inferna.png`;
const glowFlamesMaliceImage = `${imagePath}/glow/Head Glow - Malice.png`;
const glowFlamesOniImage = `${imagePath}/glow/Head Glow - Oni.png`;
const glowFlamesCyclopsImage = `${imagePath}/glow/Head Glow - Cyclops.png`;
const glowFlamesAltHeadImage = `${imagePath}/heads/balor alt head glow.png`;

// WEAPONS
const glowFlamesWhipImage = `${imagePath}/glow/Glow - Left Whip.png`;
const glowFlamesLeftNaniteClawsImage = `${imagePath}/glow/Glow - Left Nanite Claw.png`;
const glowFlamesRightNaniteClawsImage = `${imagePath}/glow/Glow - Right Nanite Claw.png`;
const glowFlamesLeftClawWhipImage = `${imagePath}/glow/Glow - Left Claw Whip.png`;
const glowFlamesRightClawWhipImage = `${imagePath}/glow/Glow - Right Claw Whip.png`;
const glowFlamesLeftAxeTethersImage = `${imagePath}/glow/Glow - Left Axe tether.png`;
const glowFlamesRightAxeTethersImage = `${imagePath}/glow/Glow - Right Axe tether.png`;
const glowSwordFlamesRightImage = `${imagePath}/glow/balor sword 1 flames.png`;
const glowAltCapeImage = `${imagePath}/glow/balor cape alt.png`;
const glowAltShoulderImage = `${imagePath}/glow/balor alt shoulder glow.png`;
const glowAnnihilatorImage = `${imagePath}/glow/balor annihilator glow.png`;

const coreImage = `${imagePath}/Balor (by BlindDuke).png`;
const core2Image = `${imagePath}/Core-Balor-Neutralista.png`;

const previewImage = `${imagePath}/preview.png`;

const bipedLegsShadow = {
    size: shadowTypes.LARGE,
    bottom: "51px",
    right: "48px",
};

export default {
    previewImg: previewImage,
    coreImg: [
        {
            src: coreImage,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "BlindDuke",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            src: core2Image,
            originalArtCredit: {
                prefixText: "Colored by ",
                title: "Neutralista",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    accessory2IsChassis: true,
    headSpecial: "rearMountValue",
    removeNone: {
        weapon: true,
        secondaryWeapon: true,
    },
    defaultValues: `{
        "chassisValue": "0",
        "headValue": "0",
        "rearMountValue": "0",
        "weaponValue": "5",
        "secondaryWeaponValue": "0",
        "accessory1Value": "NONE",
        "accessory2Value": "NONE",
        "accessory3Value": "NONE",
        "accessory4Value": "NONE",
        "stanceValue": "1H",

        "chassisTintValue": "none",
        "headTintValue": "none",
        "rearMountTintValue": "none",
        "weaponTintValue": "none",
        "secondaryWeaponTintValue": "none",
        "accessory1TintValue": "none",
        "accessory2TintValue": "none",
        "accessory3TintValue": "none",
        "accessory4TintValue": "none"
    }`,
    head: [
        {
            value: 0,
            label: "BALOR",
            src: headBalorImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: headKazuImage,
        },
        {
            value: 2,
            label: "ALTERNATE CHASSIS HEAD",
            src: emptyImage,
        },
        {
            value: 3,
            label: "MALICE",
            src: headMaliceImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ONI",
            src: headOniImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: headCyclopsImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "INFERNA",
            src: headInfernaImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCOPEHOUND",
            src: headScopehoundImage,
            isPremium: true,
        },
    ],
    chassis: [
        {
            value: 0,
            label: "COMBATANT",
            src: bodyBalorCombatImage,
            shadowDetails: bipedLegsShadow,
        },
        {
            value: 1,
            label: "COMBATANT - ALTERNATE",
            src: bodyBalorAltImage,
            shadowDetails: bipedLegsShadow,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    weapon: {
        "1H": [
            {
                value: 0,
                label: "HAND",
                src: weaponLeftHandImage,
            },
            {
                value: 1,
                label: "AXE",
                src: weaponLeftAxeImage,
            },
            {
                value: 2,
                label: "SHOTGUN",
                src: weaponLeftShotgunImage,
            },
            {
                value: 3,
                label: "FERROFLUID SPEAR",
                src: weaponLeftFerrofluidSpearImage,
            },
            {
                value: 4,
                label: "CLAW WHIP",
                src: weaponLeftClipWhipImage,
            },
            {
                value: 5,
                label: "NANITE WHIP",
                src: weaponLeftNaniteWhipImage,
            },
            {
                value: 6,
                label: "NANITE CLAW",
                src: weaponLeftNaniteClawImage,
            },
            {
                value: 7,
                label: "PILEBUNKER",
                src: weaponLeftPilebunkerImage,
            },
            {
                value: 8,
                label: "SWORD",
                src: weaponLeftSwordImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
            {
                value: 9,
                label: "LEVIATHAN CANNON - URSINE",
                src: weaponLeftLeviathanCannonUrsineImage,
                originalArtCredit: {
                    prefixText: "Created by ",
                    title: "CO_Nielsen",
                    url: "https://discord.gg/JUF47G5",
                },
            },
        ],
    },
    secondaryWeapon: [
        {
            value: 0,
            label: "HAND AND CANNON",
            src: weaponRightHandCannonImage,
        },
        {
            value: 1,
            label: "AXE",
            src: weaponRightAxeImage,
        },
        {
            value: 2,
            label: "WAR PIKE",
            src: weaponRightWarPikeImage,
        },
        {
            value: 3,
            label: "DECKSWEEPER",
            src: weaponRightDecksweeperImage,
        },
        {
            value: 4,
            label: "FLAMETHROWER",
            src: weaponRightFlamethrowerImage,
        },
        {
            value: 5,
            label: "CLAW WHIP",
            src: weaponRightClawWhipImage,
        },
        {
            value: 6,
            label: "NANITE CLAW",
            src: weaponRightNaniteClawImage,
        },
        {
            value: 7,
            label: "PILEBUNKER",
            src: weaponRightPilebunkerImage,
        },
        {
            value: 8,
            label: "SWORD",
            src: weaponRightSwordImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 9,
            label: "ANNIHILATOR",
            src: weaponRightAnnihilatorImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    rearMount: [
        {
            value: 0,
            label: "BALOR",
            src: glowFlamesBalorImage,
        },
        {
            value: 1,
            label: "KAZU",
            src: glowFlamesKazuImage,
        },
        {
            value: 2,
            label: "ALTERNATE CHASSIS HEAD",
            frontSrc: glowFlamesAltHeadImage,
        },
        {
            value: 3,
            label: "MALICE",
            src: glowFlamesMaliceImage,
            isPremium: true,
        },
        {
            value: 4,
            label: "ONI",
            src: glowFlamesOniImage,
            isPremium: true,
        },
        {
            value: 5,
            label: "CYCLOPS",
            src: glowFlamesCyclopsImage,
            isPremium: true,
        },
        {
            value: 6,
            label: "INFERNA",
            src: glowFlamesInfernaImage,
            isPremium: true,
        },
        {
            value: 7,
            label: "SCOPEHOUND",
            src: glowFlamesBalorImage,
            isPremium: true,
        },
    ],
    accessory: [
        {
            value: 0,
            label: "LEFT WHIP",
            frontSrc: glowFlamesWhipImage,
        },
        {
            value: 1,
            label: "LEFT NANITE CLAWS",
            frontSrc: glowFlamesLeftNaniteClawsImage,
        },
        {
            value: 2,
            label: "RIGHT NANITE CLAWS",
            frontSrc: glowFlamesRightNaniteClawsImage,
        },
        {
            value: 3,
            label: "LEFT CLAW WHIP",
            frontSrc: glowFlamesLeftClawWhipImage,
        },
        {
            value: 4,
            label: "RIGHT CLAW WHIP",
            frontSrc: glowFlamesRightClawWhipImage,
        },
        {
            value: 5,
            label: "LEFT AXE TETHERS",
            frontSrc: glowFlamesLeftAxeTethersImage,
        },
        {
            value: 6,
            label: "RIGHT AXE TETHERS",
            frontSrc: glowFlamesRightAxeTethersImage,
        },
        {
            value: 7,
            label: "RIGHT SWORD FLAMES",
            frontSrc: glowSwordFlamesRightImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 8,
            label: "ALTERNATE CHASSIS SHOULDER",
            frontSrc: glowAltShoulderImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 9,
            label: "ALTERNATE CHASSIS CAPE",
            backSrc: glowAltCapeImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "Gio",
                url: "https://discord.gg/JUF47G5",
            },
        },
        {
            value: 10,
            label: "ANNIHILATOR",
            frontSrc: glowAnnihilatorImage,
            originalArtCredit: {
                prefixText: "Created by ",
                title: "CO_Nielsen",
                url: "https://discord.gg/JUF47G5",
            },
        },
    ],
    partList: [
        {
            label: "ALL",
            value: "ALL",
        },
        {
            label: "BODY",
            value: "BODY",
        },
        {
            label: "FLAMES",
            value: "ACCESSORIES",
            isGlow: true,
        },
        {
            label: "WEAPONS",
            value: "WEAPONS",
        },
        {
            label: "CHASSIS",
            value: "chassis",
        },
        {
            label: "HEAD",
            value: "head",
        },
        {
            label: "LEFT WEAPON",
            value: "weapon",
        },
        {
            label: "RIGHT WEAPON",
            value: "secondaryWeapon",
        },
        {
            label: "HEAD FLAMES",
            value: "rearMount",
            isGlow: true,
        },
        {
            label: "FLAMES 1",
            value: "accessory1",
            isGlow: true,
        },
        {
            label: "FLAMES 2",
            value: "accessory3",
            isGlow: true,
        },
        {
            label: "FLAMES 3",
            value: "accessory4",
            isGlow: true,
        },
    ],
    labels: {
        "head": "HEAD",
        "chassis": "CHASSIS",
        "rearMount": "HEAD FLAMES",
        "weapon": "LEFT WEAPON",
        "secondaryWeapon": "RIGHT WEAPON",
        "accessory1": "FLAMES 1",
        "accessory2": "FLAMES 2",
        "accessory3": "FLAMES 2",
        "accessory4": "FLAMES 3",
    },
};
