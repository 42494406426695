import sherman from "./Mechs-Sherman";
import genghis from "./Mechs-Genghis";
import barbarossa from "./Mechs-Barbarossa";
import orcu from "./Mechs-Orcu";
import tokugawa from "./Mechs-Tokugawa";
import saladin from "./Mechs-Saladin";
import shields from "./Mechs-Shields";
import iskander from "./Mechs-Iskander";
import iskander2 from "./Mechs-Iskander-2";
import sunzi from "./Mechs-Sunzi";
import napoleon from "./Mechs-Napoleon";
import enkidu from "./Mechs-Enkidu";
import worldkiller from "./Mechs-Worldkiller";
import MechsHAGilgamesh from "./Mechs-HA-Gilgamesh";
import MechsHAGilgameshDrones from "./Mechs-HA-Gilgamesh-Drones";
import MechsBarbarossaMKII from "./Mechs-Barbarossa-MKII";

export default [
    Object.assign({ value: 0, label: "BARBAROSSA" }, barbarossa),
    Object.assign({ value: 1, label: "BARBAROSSA MK II" }, MechsBarbarossaMKII),
    Object.assign({ value: 2, label: "BARBAROSSA - ORUC", isPremium: true, isLegendary: true }, orcu),
    Object.assign({ value: 3, label: "ENKIDU" }, enkidu),
    Object.assign({ value: 4, label: "GENGHIS" }, genghis),
    Object.assign({ value: 5, label: "GENGHIS MK I - WORLDKILLER" }, worldkiller),
    Object.assign({ value: 6, label: "GILGAMESH" }, MechsHAGilgamesh),
    Object.assign({ value: 7, label: "GILGAMESH - DRONES" }, MechsHAGilgameshDrones),
    Object.assign({ value: 8, label: "ISKANDER 2.0" }, iskander2),
    Object.assign({ value: 9, label: "ISKANDER" }, iskander),
    Object.assign({ value: 10, label: "NAPOLEON" }, napoleon),
    Object.assign({ value: 11, label: "SALADIN" }, saladin),
    Object.assign({ value: 12, label: "SALADIN - SHIELDS" }, shields),
    Object.assign({ value: 13, label: "SHERMAN" }, sherman),
    Object.assign({ value: 14, label: "SUNZI" }, sunzi),
    Object.assign({ value: 15, label: "TOKUGAWA" }, tokugawa),
];
